@use 'src/app/styles/colors';

.share-btn-container {
    display: flex;
    margin-top: 30px;
}

.share-btn-container button {
    width: 100%;
}

.next-quiz-time {
    width: 100%;
    background-color: colors.$green-dark;
    color: white;
    text-align: center;
    padding: 8px 0;
}

@media only screen and (max-width: 600px) {
    .share-btn-container {
        max-width: 312px;
    }

    .next-quiz-time {
        position: fixed;
        bottom: 0;
        left: 0;
    }

    .download-btn-container {
        padding-bottom: 103px;
    }
}

.attempt-indicator-container {
    display: flex;
    column-gap: 10px;
    align-content: flex-start;
}