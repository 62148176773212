@use 'src/app/styles/colors';

.eg-btn {
    font-family: 'Bungee';
    font-size: 1.5rem;
    padding: 0.75rem 1rem;
    color: #383838;
    background-color: colors.$btn-yellow;
    border: 1px solid colors.$btn-black;
    border-radius: 8px;
    box-shadow: -4px 4px;
    position: relative;
    bottom: 4px;
    left: 4px;
}

.eg-btn.active {
    left: 0px;
    bottom: 0px;
    box-shadow: 0px 0px;
}

.eg-btn:active {
    animation: btn-press-in 100ms forwards;
}

.eg-btn:disabled {
    color: colors.$grey-dark;
    background-color: colors.$green-light;
    border: 1px solid colors.$grey-dark;
    animation: none;
}

.eg-btn:focus-visible {
    outline: none;
}



@keyframes btn-press-in {
    0% {
        left: 4px;
        bottom: 4px;
        box-shadow: -4px 4px;
    }

    100% {
        left: 0px;
        bottom: 0px;
        box-shadow: 0px 0px;
    }
}

@keyframes btn-press-out {
    0% {
        left: 0px;
        bottom: 0px;
        box-shadow: 0px 0px;
    }

    100% {
        left: 4px;
        bottom: 4px;
        box-shadow: -4px 4px;
    }
}