$leaderboard-text-primary: #212121;
$leaderboard-text-secondary: #757575;
$leaderboard--white-text-primary: #f0f0f0;
$leaderboard-text-red: #f65a5b;

$leaderboard-badge-main-bg: #f0f0f0;

$leaderboard-badge-silver-text: #cfcfcf;
$leaderboard-badge-silver: #757575;

$leaderboard-badge-gold: #ffa000;
$leaderboard-badge-gold-text: #ffd428;

$leaderboard-badge-bronze: #be7967;
$leaderboard-badge-bronze-text: #ddbbb2;
$leaderboard-highlight: #deecf4;
$leaderboard-share-bg: #1e1e1e;
$leaderboard-dark-grey: #b2b2b2;

.quiz-leaderboard {
  font-family: "Rubik", sans-serif;
  width: 100%;
  overflow: hidden;

  .main {
    background-color: #fff;
    overflow: hidden;
    height: 100vh;
    &.hidden {
      display: none;
    }
  }
  .table-container {
    height: calc(100vh - 152px);
    overflow: auto;
  }
  table.general-leaderboard {
    width: 100%;
    border-spacing: 0px;
    margin: 0;
    padding: 1rem 0;
    max-width: 100%;
    .name-container {
      display: flex;
      align-items: center;

      .avatar {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #f0f0f0;
        text-transform: uppercase;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          margin: 0;
        }
      }
      span {
        font-size: 0.875rem;
        margin-left: 8px;
        display: block;
        &.desc {
          margin-top: 2px;
          color: $leaderboard-text-secondary;
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
    tr {
      margin: 0;
      color: $leaderboard-text-primary;
      &.highlight {
        background-color: $leaderboard-highlight;
        position: sticky;
        bottom: 16px;
        z-index: 111;
      }
      .name-container &.empty {
        td {
          padding: 6px;
        }
      }
      &:first-child {
        td {
          border-top: 1px solid #f0f0f0;
        }
      }
      td {
        padding: 12px;
        font-weight: 400;
        margin: 0;
        border-bottom: 1px solid #f0f0f0;
        &:first-child {
          padding-left: 24px;
          text-align: center;
          color: $leaderboard-text-secondary;
          width: 20px;
        }
        &:last-child {
          text-align: center;
          padding-right: 1rem;
          font-weight: 500;
        }
        img {
          margin-left: 1rem;
        }
        .text-not-attempted {
          width: max-content;
        }
      }
      td.align-center {
        text-align: center;
      }
      td.align-left {
        text-align: left;
      }
      td.align-right {
        text-align: right;
      }
    }
  }

  .top-users {
    padding: 1rem 1rem 0px 1rem;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 136px;
    margin-bottom: 1rem;
    .leader {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-container {
        text-align: center;
        width: 100px;
        margin-top: 1rem;
        .name {
          font-weight: 400;
          font-size: 0.875rem;
          margin: 0;
        }
        .score {
          font-weight: 500;
          font-size: 0.875rem;
          margin-top: 2px;
        }
      }

      .avatar {
        width: 64px;
        height: 64px;
        border: 2px solid $leaderboard-badge-main-bg;
        position: relative;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .badge {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $leaderboard-badge-main-bg;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          bottom: -12px;
          transform: translateX(-50%);
          .inner {
            width: 16px;
            height: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $leaderboard-badge-silver-text;
            background-color: $leaderboard-badge-silver;
            border-radius: 50%;
            font-size: 0.875rem;
          }
          &.silver {
            background-color: $leaderboard-badge-silver-text;
            .inner {
              color: $leaderboard-badge-silver-text;
              background-color: $leaderboard-badge-silver;
            }
          }
          &.gold {
            background-color: $leaderboard-badge-gold-text;
            .inner {
              color: $leaderboard-badge-gold-text;
              background-color: $leaderboard-badge-gold;
            }
          }
          &.bronze {
            background-color: $leaderboard-badge-bronze-text;
            .inner {
              color: $leaderboard-badge-bronze-text;
              background-color: $leaderboard-badge-bronze;
            }
          }
        }
      }
      &.gold {
        top: 16px;
        left: 50%;
        transform: translateX(-50%);
      }
      &.bronze {
        right: 16px;
      }
      &.silver {
        left: 16px;
      }
      &.silver,
      &.bronze {
        margin-top: 1rem;
      }
    }
  }

  .share-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    overflow-x: hidden;
    .share-leaderboard {
      background-image: url("../../assets/images/share_bg.png");
      background-color: #1e1e1e;
      background-size: cover;
      height: 100vh;
      min-height: 667px;
      overflow: hidden;
      .content {
        height: 100%;
        width: 100%;
        padding-top: 1rem;
        .title {
          color: $leaderboard--white-text-primary;
          font-size: 1.375rem;
          font-weight: 500;
          text-align: center;
          width: 100%;
          margin: 0;
          margin-top: 12px;
        }
        .user-details {
          display: flex;
          flex-direction: column;
          align-items: center;
          .image-container {
            width: 208px;
            height: 208px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-container {
            margin-top: 0.5rem;
            text-align: center;
            h6.name {
              color: #f0f0f0;
              font-weight: 500;
              margin: 0;
              font-size: 1.375rem;
              line-height: 120%;
              margin-bottom: 4px;
            }
            .scores {
              display: flex;
              align-items: center;
              color: $leaderboard--white-text-primary;
              font-size: 1rem;
              font-weight: 400;
              text-align: center;
              .divider {
                height: 19px;
                width: 2px;
                background-color: $leaderboard-dark-grey;
                border-radius: 4px;
                margin: 0 4px;
              }
              b {
                color: $leaderboard-badge-gold-text;
                font-style: normal;
                font-weight: 500;
              }
            }
          }
        }
        .top-scores {
          h6 {
            margin: 0.7rem 0;
            font-size: 1rem;
            color: #f0f0f0;
            text-align: center;
          }
          table {
            width: 100%;
            border-spacing: 0px;
            margin: 0;
            padding: 0;
            max-width: 100%;

            tr {
              margin: 0;
              color: $leaderboard-badge-silver-text;
              font-weight: 400;
              &.highlight {
                background: linear-gradient(
                    0deg,
                    rgba(255, 212, 40, 0.2),
                    rgba(255, 212, 40, 0.2)
                  ),
                  #2c2c2c;
                font-weight: 500 I !important;
              }
              td {
                padding: 6px;
                font-weight: 400;
                margin: 0;
                &:first-child {
                  padding-left: 1rem;
                  text-align: left;
                }
                &:last-child {
                  text-align: right;
                  padding-right: 1rem;
                  font-weight: 500;
                }
              }
              td.align-center {
                text-align: center;
              }
              td.align-left {
                text-align: left;
              }
              td.align-right {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: calc(100% - 3rem);
    padding: 0.75rem 1.5rem;
    display: flex;

    .button-share {
      padding: 1rem;
      border-radius: 50px;
      border: none;
      outline: none;
      width: 100%;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 500;
      font-family: "Rubik", sans-serif;
      text-transform: capitalize;
      text-align: center;
      background: #59a1c9;
      &:focus,
      &:active {
        outline: none;
        border: none;
      }
    }
  }
  .text-not-attempted {
    color: $leaderboard-text-red;
    font-size: 10px;
    font-weight: 400;
    line-height: 120%;
    margin: 0;
    display: block;
  }
}

.temp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.entri-text-logo {
  width: 52px;
  height: 24px;
  position: absolute;
  top: 16px;
  left: 16px;
  background-color: #ffffff;
  padding: 3px;
  padding-right: 9px;
  border-radius: 115.657px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: auto;
  }
}
