$btn-background: linear-gradient(180deg, #f65a5b -26.04%, #7f3e98 126.04%);
$body-background: #161616;

$light-text: #fafafa;
$text-secondary: #757575;
$text-primary: #212121;
$red: #f65a5b;
$green: #39d1b4;
$yellow: #ffd428;

// Styles

.simple-quiz {
  height: 100vh;
  width: 100%;
  position: relative;
  background-color: $body-background;
  overflow: hidden;
  overflow-y: auto;
  .game-play {
    padding: 2rem 1.5rem;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .timer {
        font-family: "Bungee";
        font-size: 0.825rem;
        font-weight: 400;
        color: #fff;
      }
      .life {
        display: inline;
      }
    }
    .question {
      text-align: center;
      color: $light-text;
      font-family: "Bungee";
      margin-top: 5rem;
      width: 100%;
      .question-statement {
        margin: 0;
        margin-bottom: 3.5rem;
        font-size: 1.125rem;
        font-weight: 400;
      }
      .choices {
        .option {
          width: calc(100% - 34px);
          border: 1px solid $light-text;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          overflow: hidden;
          word-break: break-word;
          margin-bottom: 8px;
          padding: 1rem;
          transition: all 0.1s ease-in;
          &:last-of-type {
            margin: 0;
          }
          .option-statement {
            margin: 0;
            font-size: 1.25rem;
            font-weight: 400;
          }

          &.selected {
            &.wrong {
              border-color: $red;
              color: $red;
            }
            &.correct {
              border-color: $green;
              color: $green;
            }
          }
        }
      }
    }
  }
  .user-form {
    padding: 2rem 1.5rem;
    .title {
      margin-top: 4px;
      margin-bottom: 2rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      font-family: "Rubik", sans-serif;
    }
  }
  .start-screen {
    position: relative;
    padding-top: 1.5rem;
    height: calc(100% - 1.5rem);
    .title {
      margin: 0;
      font-size: 0.75rem;
      font-weight: 400;
      font-family: "Rubik", sans-serif;
      color: #ffffff;
      position: absolute;
      left: 38px;
      top: 3.5rem;
    }
    .img-container {
      width: 100%;
      display: flex;
      justify-content: center;

      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .gift {
      margin-top: 56px;
      text-align: center;
      padding: 0px 1.5rem;
      .greeting-text {
        font-family: "Rubik", sans-serif;
        font-size: 1rem;
        color: #ffffff;
        font-weight: 500;
        span {
          color: $yellow;
        }
      }
      .greeting-card {
        position: relative;
        margin-top: 2rem;
        z-index: 1;
        img {
          box-shadow: -7px -6px $light-text;
          border-radius: 1rem;
          transition: all 0.3s ease-in-out;
          &:hover {
            box-shadow: none;
            transform: translate(-7px, -6px);
          }
        }
      }
      .oval {
        width: 100%;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
      }
    }
    .btn-holder {
      bottom: 3rem;
    }
  }
  .end-screen {
    background: #ffffff;
    height: 100vh;
    .top-container {
      background: #ffffff;
      font-family: "Bungee";
      text-align: center;
      padding: 5rem 3rem;
      position: relative;
      .title {
        color: $text-primary;
        font-weight: 400;
        font-size: 1.5rem;
        margin: 0;
        margin-bottom: 2rem;
      }
      .greeting {
        color: $text-primary;
        font-weight: 400;
        font-size: 1.25rem;
        margin: 0;
      }
      .floating-image {
        position: absolute;
        animation: float 3s ease-in-out infinite;
        &.heart-r {
          left: 9.73%;
          top: 30%;
        }
        &.heart-y {
          right: 12.94%;
          bottom: 8.43%;
        }
        &.gift-y {
          right: 47.38%;
          top: 8%;
          animation-delay: 0.5s;
        }
        &.gift-g {
          left: 7.79%;
          bottom: 7.78%;
          animation-delay: 1s;
        }
        &.spring-y {
          left: 40.61%;
          bottom: 10.69%;
          animation-delay: 0.5s;
        }
        &.spring-w {
          right: 9.88%;
          top: 32.22%;
          animation-delay: 1s;
        }
      }
    }
    .bottom-container {
      background-color: $body-background;
      border-radius: 16px 16px 0px 0px;
      height: calc(100% - 329px);
      padding: 0px 60px;
      padding-top: 60px;
      text-align: center;
      .title {
        margin: auto;
        margin-bottom: 1.5rem;
        color: $light-text;
        font-family: "Bungee";
        font-size: 1rem;
        font-weight: 400;
        width: 160px;
      }
      .share-text {
        color: $light-text;
        font-family: "Bungee";
        font-size: 1rem;
        font-weight: 400;
        margin: 0;
        margin-bottom: 1.5rem;
      }
      hr {
        margin: 4rem 0px;
        border-color: rgba(250, 250, 250, 0.5);
      }

      .btn-instagram {
        margin: auto;
        background: linear-gradient(
          263.64deg,
          #5656d3 -5.45%,
          #7632c5 25.33%,
          #d5172a 60.36%,
          #ffbb01 94.33%
        );
        font-family: "Inter", sans-serif;
        font-size: 1.25rem;
        img {
          width: 100%;
          height: auto;
          margin-right: 10px;
        }
      }
      .btn-raised {
        margin: auto;
      }
    }
  }

  .form-group {
    position: relative;
    display: flex;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .floating-label {
      background-color: $body-background;
      color: #fff;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      font-weight: 500;
      font-size: 1rem;
      line-height: 23px;
      transform: translate(16px, 16px);
      transition: transform 0.2s ease;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 1000s;
    }

    input:-internal-autofill-selected {
      color: #fff !important;
      background-color: $body-background;
    }
    input,
    textarea {
      background-color: $body-background;
      color: $text-secondary !important;
      padding: 1rem;
      outline: none;
      font-weight: 600;
      font-size: 1rem;
      border: 1px solid $light-text;
      width: 100%;
      &:focus,
      &:valid {
        border-width: 2px;
        position: relative;
        margin-bottom: -2px;
      }
      &:focus ~ .floating-label,
      &:valid ~ .floating-label {
        transform: translate(8px, -16px);
        padding: 8px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 16px;
        border-color: #212121;
      }
      &:invalid:not(:placeholder-shown) {
        border-color: $red;
      }
      &:invalid:not(:placeholder-shown) ~ span {
        display: block;
      }
      &:invalid:not(:placeholder-shown) ~ .floating-label {
        transform: translate(8px, -16px);
        padding: 8px;
        font-weight: 400;
        line-height: 16px;
        color: $red;
      }
    }

    select {
      padding: 1rem;
      outline: none;
      font-weight: 600;
      font-size: 1rem;
      border: 2px solid #898989;
      background-color: #ffffff;
      width: 100%;
    }
  }
  @media (min-width: 576px) {
    margin-top: -7vh;
    min-height: 745px;
    max-width: 360px;
  }
  // Small devices (landscape phones, 576px and up)
  @media (max-width: 576px) {
    .form-group {
      .floating-label {
        font-size: 0.875rem;
      }
      input,
      textarea {
        font-size: 0.875rem;
        &:focus ~ .floating-label,
        &:valid ~ .floating-label {
          font-size: 0.75rem;
        }
      }
    }
  }
  a.link {
    text-transform: none;
    text-decoration: none;
  }

  .btn-holder {
    position: absolute;
    left: 50%;
    bottom: 48px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.25rem;
    font-family: "Bungee";
    cursor: pointer;
    &.btn-raised {
      width: max-content;
      box-shadow: -4px 4px $light-text;
      background: $btn-background;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      border: 1px solid $light-text;

      &:active {
        animation: btn-press-in 100ms forwards;
      }

      &:disabled {
        animation: none;
        filter: saturate(0.5);
        cursor: not-allowed;
      }

      &:focus-visible {
        outline: none;
      }
    }
    & .loader {
      width: 106px;
      span {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: rgba(255, 255, 255, 0.4);
        margin: 0px 4px;
        &:nth-child(1) {
          animation: bounce 0.8s ease-in-out infinite;
        }
        &:nth-child(2) {
          animation: bounce 0.8s ease-in-out 0.33s infinite;
        }
        &:nth-child(3) {
          animation: bounce 0.8s ease-in-out 0.66s infinite;
        }
      }
    }

    @keyframes bounce {
      0%,
      75%,
      100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }

      25% {
        -webkit-transform: translateY(-8px);
        -ms-transform: translateY(-8px);
        -o-transform: translateY(-8px);
        transform: translateY(-8px);
      }
    }
  }

  @keyframes btn-press-in {
    0% {
      left: 4px;
      bottom: 4px;
      box-shadow: -4px 4px;
    }

    90% {
      left: 0px;
      bottom: 0px;
      box-shadow: 0px 0px;
      transform: translate(-4px, 4px);
    }
    100% {
      left: 4px;
      bottom: 4px;
      box-shadow: -4px 4px;
    }
  }

  @keyframes btn-press-out {
    0% {
      left: 0px;
      bottom: 0px;
      box-shadow: 0px 0px;
    }

    100% {
      left: 4px;
      bottom: 4px;
      box-shadow: -4px 4px;
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0, 0);
    }

    50% {
      transform: translateY(-5px);
    }

    100% {
      transform: translateY(0);
    }
  }
}
