@use 'src/app/styles/colors';

.level {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 139px;
}

.question-container {
    text-align: center;
}

.options-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 20px;
    flex-grow: 1;

    & .option {
        width: calc(50% - 12px);
        text-align: start;
        word-break: break-word;
    }
}


.action-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    gap: 16px;

    button {
        margin: auto;
    }

    .attempt-progress {
        font-family: 'Inter', sans-serif;
        color: colors.$text-grey;
        font-size: 0.875rem;
        display: inline-flex;
        align-items: center;
        gap: 3px;

        &.completed {
            color: colors.$text-dark;
        }

        strong {
            font-size: 1rem;
            font-weight: 700;
            color: colors.$text-dark;
        }
    }

}

@media only screen and (max-width: 600px) {
    .action-btn-container {
        position: fixed;
        bottom: 0;
        width: calc(100% - 48px);
        max-width: 312px;
        background: linear-gradient(0deg, white 70%, transparent 100%);
    }

}