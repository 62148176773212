.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2rem;
}

.btn-container {
  display: flex;
  justify-content: end;
  margin-top: 1.5rem;
  .btn {
    font-weight: bold;
    outline: none;
    border: none;
    margin: 0;
    padding: 0.75rem 1rem;
    margin-right: 1rem;
    text-transform: uppercase;
    cursor: pointer;

    &.submit {
      background-color: #59a1c9;
      color: white;
      &:disabled {
        cursor: not-allowed;
        background-color: #85aec5;
      }
    }
    &:last-child {
      margin: 0;
    }
    &:focus,
    &:active,
    &:hover {
      outline: none !important;
    }
  }
}

.form-group {
  position: relative;
  display: flex;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  .floating-label {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 23px;
    background-color: white;
    transform: translate(16px, 16px);
    transition: transform 0.2s ease;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 1000s;
  }
  input,
  textarea {
    padding: 1rem;
    outline: none;
    font-weight: 600;
    font-size: 1rem;
    border: 2px solid #898989;
    width: 100%;
    &:focus,
    &:valid {
      border-width: 2px;
      border-color: #59a1c9;
    }
    &:focus ~ .floating-label,
    &:valid ~ .floating-label {
      transform: translate(8px, -16px);
      padding: 8px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 16px;
      border-color: #212121;
    }
    &:invalid:not(:placeholder-shown) {
      border-color: rgba(245, 9, 5, 0.901);
    }
    &:invalid:not(:placeholder-shown) ~ span {
      display: block;
    }
    &:invalid:not(:placeholder-shown) ~ .floating-label {
      transform: translate(8px, -16px);
      padding: 8px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 16px;
      color: rgba(245, 9, 5, 0.901);
    }
  }

  select {
    padding: 1rem;
    outline: none;
    font-weight: 600;
    font-size: 1rem;
    border: 2px solid #898989;
    background-color: #FFFFFF;
    width: 100%;
  }
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .form-group {
    .floating-label {
      font-size: 0.875rem;
    }
    input,
    textarea {
      font-size: 0.875rem;
      &:focus ~ .floating-label,
      &:valid ~ .floating-label {
        font-size: 0.75rem;
      }
    }
  }
}
