$background: #FFFFFF;

// Typography
$text-dark: #212121;
$text-light: #FFFFFF;
$text-grey: #757575;

// Button
$btn-yellow: #FDD835;
$btn-black: #383838;

// Disabled
$grey-dark: #B2B2B2;
$grey-light: #F0F0F0;

// Selectable buton color variants
$violet-dark: #84429D;
$violet-light: #F9D8FF26;

$green-dark: #006B55;
$green-light: #D8E9B726;

$blue-dark: #00658B;
$blue-light: #C5E7FF26;

$yellow-dark: #C68400;
$yellow-light: #FFE54C26;

$red-dark: red;
$red-light: #FF000016;

// Card
$card-border: #C68400;
$card-body: #FFE54C26;

// Game status
$green2-dark: #006B55;
$green2-light: #006B5533;

$red-dark: #B2282F;
$red-light: #B2282F33;