$spoken-text-primary: #212121;
$spoken-text-secondary: #383838;
$spoken-text-green: #00ad8b;
$spoken-text-red: #f65a5b;
$spoken-btn-green: #39d1b4;
$spoken-btn-green-light: rgba(57, 209, 180, 0.5);
$spoken-btn-yellow: #fdd835;
$spoken-btn-yellow-light: rgba(255, 212, 40, 0.5);
$spoken-btn-yellow-dark: rgba(255, 212, 40, 0.8);

$spoken-red: rgba(246, 90, 91, 0.1);
$spoken-green: rgba(0, 173, 139, 0.1);
$spoken-yellow: rgba(255, 212, 40, 0.8);
.playground {
  border-radius: 1rem;
  border: 2px solid rgb(146, 143, 143);
  text-align: center;
  max-width: 360px;
  width: 100%;
  min-height: 640px;
  max-height: 640px;
  height: 100%;
  position: relative;
  background: linear-gradient(
    180deg,
    rgba(255, 212, 40, 0.15) 0%,
    rgba(57, 209, 180, 0.15) 100%
  );
  .verbs {
    height: 98%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    .title {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin: 0;
      margin-top: 2rem;
    }
    .verbs-match {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      min-height: 400px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        .element {
          padding: 0.5rem 1rem;
          text-align: center;
          background-color: $spoken-btn-yellow-light;
          font-size: 1rem;
          font-weight: 500;
          color: #3a3d3c;
          margin: 1rem 0px;
          border-radius: 2px;
          text-transform: uppercase;
          min-width: 85px;
          transition: opacity, background-color 0.4s ease;
          opacity: 1;
          cursor: grab;
          &:first-child {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &.correct {
            background-color: $spoken-btn-green-light;
            -webkit-animation: fadeout 1s ease-in-out both;
            animation: fadeout 1s ease-in-out both;
          }
          &.wrong {
            background-color: rgba(255, 73, 73, 0.5);
            border-color: rgba(255, 73, 73, 0.5);
            -webkit-animation: shake-horizontal 0.4s linear;
            animation: shake-horizontal 0.4s linear;
          }
          &:hover {
            cursor: grabbing;
          }
        }
      }
    }
  }

  .life {
    border-radius: 0.4rem;
    padding: 9.75px 1rem;
    position: absolute;
    left: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    span {
      font-size: 1rem;
      color: blue;
      &.empty {
        color: #4e4e4e;
      }
    }
  }

  .score {
    border: 1px solid $spoken-text-primary;
    border-radius: 0.4rem;
    text-align: center;
    padding: 0.625rem 1rem;
    width: 84px;
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Roboto, sans-serif;
    h6 {
      margin: 0;
      font-size: 0.75rem;
      text-transform: uppercase;
      color: #3a3d3c;
      margin-right: 0.5rem;
    }
    span {
      font-size: 0.75rem;
      font-weight: bold;
      color: #3a3d3c;
      width: 24px;
    }
  }

  .game-over-screen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .circle {
      width: 316px;
      height: 316px;
      border-radius: 50%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      -webkit-animation: scale-up-center 0.4s
        cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
      animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55)
        both;
      &.game-over {
        background-color: $spoken-red;
        h2 {
          color: $spoken-text-red;
        }
      }
      &.game-finished {
        background-color: $spoken-green;
        h2 {
          color: $spoken-text-green;
          line-height: 65%;
          span {
            font-size: 34px;
          }
        }
      }
    }

    h2 {
      font-size: 3rem;
      margin: 0;
      line-height: 85%;
      font-family: "Bungee";
    }
    h3 {
      font-family: "Roboto", sans-serif;
      font-size: 1.5rem;
      margin: 0;
      margin-top: 2.5rem;
    }
  }

  .start-screen {
    width: calc(100% - 2rem);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 0 1rem;
    h1 {
      font-size: 2.5rem;
      font-family: "Bungee";
      margin: 0;
      margin-bottom: 1.5rem;
      line-height: 120%;
    }
    h2 {
      display: inline;
      width: fit-content;
      font-size: 0.825rem;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: $spoken-text-green;
      text-transform: uppercase;
      position: relative;
      &::after {
        content: "-";
        position: absolute;
        top: 0px;
        right: -15px;
      }
      &::before {
        content: "-";
        position: absolute;
        top: 0px;
        left: -15px;
      }
    }
  }
  .btn-container {
    width: 100%;
    position: absolute;
    bottom: 2rem;
    left: 0;
    padding: 2rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .btn {
    background-color: white;
    padding: 1rem;
    border: none;
    outline: none;
    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Bungee";
    cursor: pointer;
    &.start {
      margin-right: 2rem;
    }
  }
  .game-play {
    height: calc(100% - 4rem);
    margin-top: 4rem;
    &.deactivate {
      -webkit-animation: fade-out 1s ease-out both;
      animation: fade-out 1s ease-out both;
    }
    .swiper-wrapper {
      min-height: 100%;
    }
    .swiper-backface-hidden .swiper-slide {
      min-height: 520px;
      height: 100%;
      overflow: hidden;
    }
  }
}
/**
  Fill in the blanks
*/
.fill-in-blanks {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  .question-container {
    padding: 1rem;
    width: 100%;
    .question-statement {
      width: 100%;
      font-size: 1.35rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
      word-spacing: 4px;
      color: $spoken-text-primary;
      .blank {
        border-bottom: 2px solid #3a3d3c;
        width: 60px;
        display: inline-block;
        margin: 0px 4px;
        border-radius: 4px;
        &.active {
          border: none;
          padding: 0 6px;
          display: inline;
          margin: 0;
        }
        &.correct {
          border: none;
          margin: 0;
          padding: 0 6px;
          color: rgba(3, 148, 63, 0.901) !important;
        }
        &.wrong {
          border: none;
          color: rgba(241, 9, 9, 0.934) !important;
        }
        &.hover {
          border: none;
          padding: 0 6px;
          display: inline;
          margin: 0;
          color: #007bff;
        }
      }
    }
    .choice-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 160px;
      .choice {
        font-size: 1rem;
        padding: 0.5rem;
        background-color: transparent;
        border: 1px solid #000000;
        border-radius: 2px;
        outline: none;
        margin: 1rem 0.5rem;
        height: fit-content;
        text-transform: uppercase;
        cursor: pointer;
        &:disabled {
          color: #939393;
          border-color: #939393;
        }
        &.correct {
          font-weight: bold;
          color: #3a3d3c;
          background-color: rgba(2, 226, 95, 0.449);
          border-color: rgba(2, 226, 95, 0.449);
        }
        &.wrong {
          font-weight: bold;
          border-color: rgba(255, 73, 73, 0.5);
          background-color: rgba(255, 73, 73, 0.5);
          -webkit-animation: shake-horizontal-fadeout 0.5s linear;
          animation: shake-horizontal-fadeout 0.5s linear;
        }
      }
    }
  }
}

/**
  Leaderboard
**/
.leaderboard {
  border-radius: 1rem;
  border: 2px solid rgb(146, 143, 143);
  text-align: center;
  min-height: 640px;
  max-height: 640px;
  max-width: 360px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(255, 212, 40, 0.15) 0%,
    rgba(57, 209, 180, 0.15) 100%
  );
  font-family: "Roboto", sans-serif;
  .particle-circle {
    position: absolute;
    width: 451px;
    height: 451px;
    background: #00ad8b;
    opacity: 0.1;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
    &.inner {
      top: -254px;
    }
    &.outer {
      top: -244px;
    }
    z-index: -1;
  }
  .v-none {
    visibility: hidden;
  }
  .score {
    font-family: "Bungee";
    font-size: 2.125rem;
    line-height: 85%;
    color: $spoken-text-secondary;
  }
  .score-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    .score-circle {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px solid #000000;
      border-radius: 50%;
      background-color: $spoken-yellow;
      color: $spoken-text-primary;
      font-weight: 700;
      font-size: 1rem;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-animation: scale-up-center 0.4s
        cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
      animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    .divider {
      height: 100%;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.5);
      margin: 0 1.125rem;
    }
    .rank-circle {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px solid #000000;
      color: $spoken-text-primary;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      -webkit-animation: scale-up-center 0.4s
        cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
      animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      p {
        margin: 0;
        font-weight: 700;
        font-size: 1.25rem;
        sup {
          font-weight: 700;
          font-size: 0.375rem;
          line-height: 120%;
          text-transform: uppercase;
          vertical-align: 0.875rem;
        }
      }
      span {
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 120%;
        text-transform: uppercase;
      }
    }
  }
  .leaderboard-list {
    width: 100%;
    height: 40%;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 3rem;
    table {
      width: 100%;
      border-spacing: 0px;
      margin: 0;
      padding: 1rem;
      max-width: 100%;
      font-family: "Roboto", sans-serif;
      tr {
        margin: 0;
        color: $spoken-text-primary;
        &.highlight {
          color: $spoken-text-green;
        }
        &:first-child {
          td {
          }
        }
        &:nth-child(2) {
          td {
          }
        }
        &:nth-child(3) {
          td {
          }
        }

        td {
          font-size: 1rem;
          padding: 0.5rem;
          font-weight: 500;
          margin: 0;
          border-color: #f0eded;
          border-bottom: 1px solid;
          &:first-child {
            padding-left: 0;
            text-align: left;
          }
          &:last-child {
            text-align: right;
            padding-right: 0px;
          }
          span {
            font-size: 0.725rem;
          }
          img {
            margin-left: 1rem;
          }
        }
        td.align-center {
          text-align: center;
        }
        td.align-left {
          text-align: left;
        }
        td.align-right {
          text-align: right;
        }
      }
    }
  }
  .btn-spoken {
    margin: 0;
    &.start {
      margin: 0;
    }
  }
  .btn-container {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      display: block;
    }
    .btn.btn-save-score {
      background-color: transparent;
      margin: 0;
      color: $spoken-text-green;
      text-decoration: underline;
      visibility: hidden;
      &.visible {
        visibility: visible;
      }
    }
    .btn-spoken.start {
      margin-top: 1rem;
    }
    .scores {
      margin: 0px;
      margin-bottom: 1rem;
    }
  }
}
.element-active {
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: $spoken-btn-yellow-dark;
  font-size: 1rem;
  font-weight: 500;
  color: #3a3d3c;
  margin: 1rem 0px;
  border-radius: 2px;
  text-transform: uppercase;
  min-width: 85px;
  transition: opacity, background-color 0.4s ease;
  opacity: 1;
  cursor: grabbing;
}

.material-icons-round {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

/** 

*/
.btn-spoken {
  font-family: "Bungee";
  font-size: 1.5rem;
  padding: 0.75rem 1rem;
  color: #383838;
  border: 1px solid $spoken-text-primary;
  border-radius: 8px;
  box-shadow: -4px 4px;
  position: relative;
  bottom: 4px;
  left: 4px;
  cursor: pointer;
  &.scores {
    background-color: $spoken-btn-green;
    margin-left: 2.625rem;
  }
  &.start {
    background-color: $spoken-btn-yellow;
    margin-right: 2.625rem;
  }
}

.btn-spoken.active {
  left: 0px;
  bottom: 0px;
  box-shadow: 0px 0px;
}

.btn-spoken:active {
  animation: btn-press-in 100ms forwards;
}

.btn-spoken:disabled {
  // color: colors.$grey-dark;
  // background-color: colors.$green-light;
  // border: 1px solid colors.$grey-dark;
  animation: none;
}

.btn-spoken:focus-visible {
  outline: none;
}

@keyframes btn-press-in {
  0% {
    left: 4px;
    bottom: 4px;
    box-shadow: -4px 4px;
  }

  100% {
    left: 0px;
    bottom: 0px;
    box-shadow: 0px 0px;
  }
}

@keyframes btn-press-out {
  0% {
    left: 0px;
    bottom: 0px;
    box-shadow: 0px 0px;
  }

  100% {
    left: 4px;
    bottom: 4px;
    box-shadow: -4px 4px;
  }
}

/**
  Winning animation
*/

svg use {
  fill: transparent;
  stroke: rgba(63, 63, 63, 0.7);
  stroke-width: 2;
  transition: all 0.33s ease;
}

svg use:last-child {
  fill: $spoken-text-primary;
  stroke: $spoken-text-primary;
  opacity: 0;
  transform: scale(0.33);
  transform-origin: center;
}

.fill svg use {
  stroke: transparent;
}

.fill svg use:last-child {
  opacity: 1;
  transform: none;
  transition: all 0.5s cubic-bezier(0.19, 2.41, 0.45, 0.94);
}

svg {
  width: 16px;
  height: 16px;
  overflow: visible !important;
  margin-right: 4px;
}

.hide {
  display: none;
}

.win-anim {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 10%;
  overflow: hidden;
  pointer-events: none;
  display: none;
  &.show {
    display: block;
  }
  .pyro > .before,
  .pyro > .after {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
      0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    -moz-animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    -webkit-animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    -o-animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    -ms-animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
    animation: 1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards;
  }

  .pyro > .after {
    -moz-animation-delay: 1.25s, 1.25s, 1.25s;
    -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
    -o-animation-delay: 1.25s, 1.25s, 1.25s;
    -ms-animation-delay: 1.25s, 1.25s, 1.25s;
    animation-delay: 1.25s, 1.25s, 1.25s;
    -moz-animation-duration: 1.25s, 1.25s, 6.25s;
    -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
    -o-animation-duration: 1.25s, 1.25s, 6.25s;
    -ms-animation-duration: 1.25s, 1.25s, 6.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
  }

  @-webkit-keyframes bang {
    to {
      box-shadow: 61px 60.6666666667px #6f00ff, -205px -125.3333333333px #00ff04,
        187px -243.3333333333px #9d00ff, -158px -26.3333333333px #59ff00,
        -188px -223.3333333333px #00ffa6, -136px -48.3333333333px #11ff00,
        116px -5.3333333333px deepskyblue, -157px -182.3333333333px #00f2ff,
        -188px -0.3333333333px #6aff00, -64px -295.3333333333px #00f2ff,
        -94px -161.3333333333px #ff9900, -193px 6.6666666667px #00ffea,
        231px -137.3333333333px #e1ff00, 236px -244.3333333333px #2600ff,
        45px -305.3333333333px #ff0900, -70px 20.6666666667px #00ff5e,
        -154px -76.3333333333px #e600ff, 250px -238.3333333333px darkorange,
        198px 0.6666666667px #ff0059, -53px -231.3333333333px #a200ff,
        219px -331.3333333333px #5500ff, 71px -228.3333333333px #5e00ff,
        -214px -314.3333333333px #5e00ff, 55px -188.3333333333px #ff1e00,
        -80px -196.3333333333px #006aff, 167px -112.3333333333px #0066ff,
        -39px -155.3333333333px #5500ff, 92px -62.3333333333px #ff7700,
        174px -274.3333333333px #fb00ff, -147px -248.3333333333px #9dff00,
        -229px 22.6666666667px #00ff84, 92px -227.3333333333px #007bff,
        208px -44.3333333333px #ff8800, -120px -205.3333333333px #b7ff00,
        154px 11.6666666667px #00ff0d, -181px -74.3333333333px #ff00f2,
        199px -328.3333333333px #00ff3c, -64px -5.3333333333px #b7ff00,
        103px -107.3333333333px #ffaa00, -88px -10.3333333333px #8c00ff,
        3px -239.3333333333px #ff006f, 250px -93.3333333333px #001eff,
        -163px -185.3333333333px #aaff00, -116px -71.3333333333px #1eff00,
        -86px -115.3333333333px #006fff, -102px -278.3333333333px #aeff00,
        200px -211.3333333333px #0900ff, -144px -321.3333333333px #00eaff,
        -148px -30.3333333333px #006aff, -194px 43.6666666667px #ff6f00,
        136px -82.3333333333px #9500ff, -201px -125.3333333333px #ff2b00,
        27px 23.6666666667px #00ffae, -171px 30.6666666667px #c8ff00,
        31px -206.3333333333px #ff7300, -110px -32.3333333333px #ff006f,
        110px -309.3333333333px #ffea00, -158px -61.3333333333px #002bff,
        212px -224.3333333333px #00ff09, 196px -197.3333333333px #001aff,
        -148px -143.3333333333px #2fff00;
    }
  }
  @-moz-keyframes bang {
    to {
      box-shadow: 61px 60.6666666667px #6f00ff, -205px -125.3333333333px #00ff04,
        187px -243.3333333333px #9d00ff, -158px -26.3333333333px #59ff00,
        -188px -223.3333333333px #00ffa6, -136px -48.3333333333px #11ff00,
        116px -5.3333333333px deepskyblue, -157px -182.3333333333px #00f2ff,
        -188px -0.3333333333px #6aff00, -64px -295.3333333333px #00f2ff,
        -94px -161.3333333333px #ff9900, -193px 6.6666666667px #00ffea,
        231px -137.3333333333px #e1ff00, 236px -244.3333333333px #2600ff,
        45px -305.3333333333px #ff0900, -70px 20.6666666667px #00ff5e,
        -154px -76.3333333333px #e600ff, 250px -238.3333333333px darkorange,
        198px 0.6666666667px #ff0059, -53px -231.3333333333px #a200ff,
        219px -331.3333333333px #5500ff, 71px -228.3333333333px #5e00ff,
        -214px -314.3333333333px #5e00ff, 55px -188.3333333333px #ff1e00,
        -80px -196.3333333333px #006aff, 167px -112.3333333333px #0066ff,
        -39px -155.3333333333px #5500ff, 92px -62.3333333333px #ff7700,
        174px -274.3333333333px #fb00ff, -147px -248.3333333333px #9dff00,
        -229px 22.6666666667px #00ff84, 92px -227.3333333333px #007bff,
        208px -44.3333333333px #ff8800, -120px -205.3333333333px #b7ff00,
        154px 11.6666666667px #00ff0d, -181px -74.3333333333px #ff00f2,
        199px -328.3333333333px #00ff3c, -64px -5.3333333333px #b7ff00,
        103px -107.3333333333px #ffaa00, -88px -10.3333333333px #8c00ff,
        3px -239.3333333333px #ff006f, 250px -93.3333333333px #001eff,
        -163px -185.3333333333px #aaff00, -116px -71.3333333333px #1eff00,
        -86px -115.3333333333px #006fff, -102px -278.3333333333px #aeff00,
        200px -211.3333333333px #0900ff, -144px -321.3333333333px #00eaff,
        -148px -30.3333333333px #006aff, -194px 43.6666666667px #ff6f00,
        136px -82.3333333333px #9500ff, -201px -125.3333333333px #ff2b00,
        27px 23.6666666667px #00ffae, -171px 30.6666666667px #c8ff00,
        31px -206.3333333333px #ff7300, -110px -32.3333333333px #ff006f,
        110px -309.3333333333px #ffea00, -158px -61.3333333333px #002bff,
        212px -224.3333333333px #00ff09, 196px -197.3333333333px #001aff,
        -148px -143.3333333333px #2fff00;
    }
  }
  @-o-keyframes bang {
    to {
      box-shadow: 61px 60.6666666667px #6f00ff, -205px -125.3333333333px #00ff04,
        187px -243.3333333333px #9d00ff, -158px -26.3333333333px #59ff00,
        -188px -223.3333333333px #00ffa6, -136px -48.3333333333px #11ff00,
        116px -5.3333333333px deepskyblue, -157px -182.3333333333px #00f2ff,
        -188px -0.3333333333px #6aff00, -64px -295.3333333333px #00f2ff,
        -94px -161.3333333333px #ff9900, -193px 6.6666666667px #00ffea,
        231px -137.3333333333px #e1ff00, 236px -244.3333333333px #2600ff,
        45px -305.3333333333px #ff0900, -70px 20.6666666667px #00ff5e,
        -154px -76.3333333333px #e600ff, 250px -238.3333333333px darkorange,
        198px 0.6666666667px #ff0059, -53px -231.3333333333px #a200ff,
        219px -331.3333333333px #5500ff, 71px -228.3333333333px #5e00ff,
        -214px -314.3333333333px #5e00ff, 55px -188.3333333333px #ff1e00,
        -80px -196.3333333333px #006aff, 167px -112.3333333333px #0066ff,
        -39px -155.3333333333px #5500ff, 92px -62.3333333333px #ff7700,
        174px -274.3333333333px #fb00ff, -147px -248.3333333333px #9dff00,
        -229px 22.6666666667px #00ff84, 92px -227.3333333333px #007bff,
        208px -44.3333333333px #ff8800, -120px -205.3333333333px #b7ff00,
        154px 11.6666666667px #00ff0d, -181px -74.3333333333px #ff00f2,
        199px -328.3333333333px #00ff3c, -64px -5.3333333333px #b7ff00,
        103px -107.3333333333px #ffaa00, -88px -10.3333333333px #8c00ff,
        3px -239.3333333333px #ff006f, 250px -93.3333333333px #001eff,
        -163px -185.3333333333px #aaff00, -116px -71.3333333333px #1eff00,
        -86px -115.3333333333px #006fff, -102px -278.3333333333px #aeff00,
        200px -211.3333333333px #0900ff, -144px -321.3333333333px #00eaff,
        -148px -30.3333333333px #006aff, -194px 43.6666666667px #ff6f00,
        136px -82.3333333333px #9500ff, -201px -125.3333333333px #ff2b00,
        27px 23.6666666667px #00ffae, -171px 30.6666666667px #c8ff00,
        31px -206.3333333333px #ff7300, -110px -32.3333333333px #ff006f,
        110px -309.3333333333px #ffea00, -158px -61.3333333333px #002bff,
        212px -224.3333333333px #00ff09, 196px -197.3333333333px #001aff,
        -148px -143.3333333333px #2fff00;
    }
  }
  @-ms-keyframes bang {
    to {
      box-shadow: 61px 60.6666666667px #6f00ff, -205px -125.3333333333px #00ff04,
        187px -243.3333333333px #9d00ff, -158px -26.3333333333px #59ff00,
        -188px -223.3333333333px #00ffa6, -136px -48.3333333333px #11ff00,
        116px -5.3333333333px deepskyblue, -157px -182.3333333333px #00f2ff,
        -188px -0.3333333333px #6aff00, -64px -295.3333333333px #00f2ff,
        -94px -161.3333333333px #ff9900, -193px 6.6666666667px #00ffea,
        231px -137.3333333333px #e1ff00, 236px -244.3333333333px #2600ff,
        45px -305.3333333333px #ff0900, -70px 20.6666666667px #00ff5e,
        -154px -76.3333333333px #e600ff, 250px -238.3333333333px darkorange,
        198px 0.6666666667px #ff0059, -53px -231.3333333333px #a200ff,
        219px -331.3333333333px #5500ff, 71px -228.3333333333px #5e00ff,
        -214px -314.3333333333px #5e00ff, 55px -188.3333333333px #ff1e00,
        -80px -196.3333333333px #006aff, 167px -112.3333333333px #0066ff,
        -39px -155.3333333333px #5500ff, 92px -62.3333333333px #ff7700,
        174px -274.3333333333px #fb00ff, -147px -248.3333333333px #9dff00,
        -229px 22.6666666667px #00ff84, 92px -227.3333333333px #007bff,
        208px -44.3333333333px #ff8800, -120px -205.3333333333px #b7ff00,
        154px 11.6666666667px #00ff0d, -181px -74.3333333333px #ff00f2,
        199px -328.3333333333px #00ff3c, -64px -5.3333333333px #b7ff00,
        103px -107.3333333333px #ffaa00, -88px -10.3333333333px #8c00ff,
        3px -239.3333333333px #ff006f, 250px -93.3333333333px #001eff,
        -163px -185.3333333333px #aaff00, -116px -71.3333333333px #1eff00,
        -86px -115.3333333333px #006fff, -102px -278.3333333333px #aeff00,
        200px -211.3333333333px #0900ff, -144px -321.3333333333px #00eaff,
        -148px -30.3333333333px #006aff, -194px 43.6666666667px #ff6f00,
        136px -82.3333333333px #9500ff, -201px -125.3333333333px #ff2b00,
        27px 23.6666666667px #00ffae, -171px 30.6666666667px #c8ff00,
        31px -206.3333333333px #ff7300, -110px -32.3333333333px #ff006f,
        110px -309.3333333333px #ffea00, -158px -61.3333333333px #002bff,
        212px -224.3333333333px #00ff09, 196px -197.3333333333px #001aff,
        -148px -143.3333333333px #2fff00;
    }
  }
  @keyframes bang {
    to {
      box-shadow: 61px 60.6666666667px #6f00ff, -205px -125.3333333333px #00ff04,
        187px -243.3333333333px #9d00ff, -158px -26.3333333333px #59ff00,
        -188px -223.3333333333px #00ffa6, -136px -48.3333333333px #11ff00,
        116px -5.3333333333px deepskyblue, -157px -182.3333333333px #00f2ff,
        -188px -0.3333333333px #6aff00, -64px -295.3333333333px #00f2ff,
        -94px -161.3333333333px #ff9900, -193px 6.6666666667px #00ffea,
        231px -137.3333333333px #e1ff00, 236px -244.3333333333px #2600ff,
        45px -305.3333333333px #ff0900, -70px 20.6666666667px #00ff5e,
        -154px -76.3333333333px #e600ff, 250px -238.3333333333px darkorange,
        198px 0.6666666667px #ff0059, -53px -231.3333333333px #a200ff,
        219px -331.3333333333px #5500ff, 71px -228.3333333333px #5e00ff,
        -214px -314.3333333333px #5e00ff, 55px -188.3333333333px #ff1e00,
        -80px -196.3333333333px #006aff, 167px -112.3333333333px #0066ff,
        -39px -155.3333333333px #5500ff, 92px -62.3333333333px #ff7700,
        174px -274.3333333333px #fb00ff, -147px -248.3333333333px #9dff00,
        -229px 22.6666666667px #00ff84, 92px -227.3333333333px #007bff,
        208px -44.3333333333px #ff8800, -120px -205.3333333333px #b7ff00,
        154px 11.6666666667px #00ff0d, -181px -74.3333333333px #ff00f2,
        199px -328.3333333333px #00ff3c, -64px -5.3333333333px #b7ff00,
        103px -107.3333333333px #ffaa00, -88px -10.3333333333px #8c00ff,
        3px -239.3333333333px #ff006f, 250px -93.3333333333px #001eff,
        -163px -185.3333333333px #aaff00, -116px -71.3333333333px #1eff00,
        -86px -115.3333333333px #006fff, -102px -278.3333333333px #aeff00,
        200px -211.3333333333px #0900ff, -144px -321.3333333333px #00eaff,
        -148px -30.3333333333px #006aff, -194px 43.6666666667px #ff6f00,
        136px -82.3333333333px #9500ff, -201px -125.3333333333px #ff2b00,
        27px 23.6666666667px #00ffae, -171px 30.6666666667px #c8ff00,
        31px -206.3333333333px #ff7300, -110px -32.3333333333px #ff006f,
        110px -309.3333333333px #ffea00, -158px -61.3333333333px #002bff,
        212px -224.3333333333px #00ff09, 196px -197.3333333333px #001aff,
        -148px -143.3333333333px #2fff00;
    }
  }
  @-webkit-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-moz-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-o-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-ms-keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0;
    }
  }
  @-webkit-keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-moz-keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-o-keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @-ms-keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
  @keyframes position {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
}
/** 

*/
// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .playground {
    border: none;
    border-radius: 0;
    max-width: unset;
    max-height: unset;
  }
  .leaderboard {
    border: none;
    border-radius: 0;
    max-width: unset;
    max-height: unset;
  }
}

/**
Animations
*/

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-21 15:13:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  30%,
  60%,
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  10%,
  40%,
  70% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
}
@-webkit-keyframes shake-horizontal-fadeout {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  80% {
    opacity: 0.5;
  }
}
@keyframes shake-horizontal-fadeout {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 0;
  }
  30%,
  60%,
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
  10%,
  40%,
  70% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  80% {
    opacity: 0.5;
  }
}
