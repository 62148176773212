@use "src/app/styles/colors";

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

@media only screen and (min-width: 600px) {
  .app {
    height: 70vh;
    margin-top: 10vh;
  }
}

@media only screen and (max-width: 600px) {
  .app .container {
    height: calc(100% - 139px);
    margin-top: 139px;
  }
}

.app .container {
  width: calc(100% - 48px);
  max-width: 312px;
  font-family: "Inter";
  flex-grow: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}
