.modal-base {
  top: 50%;
  left: 50%;
  position: relative;
  background-color: #fefefe;
  padding: 2rem;
  border: 1px solid #888;
  border-radius: 1rem;
  width: 320px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8) translate(-50%, -50%);

  &:focus {
    outline: none;
  }
}

.modal-base_after-open {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.modal-base_before-close {
  transform: scale(0.2) translate(-130%, -135%);
  opacity: 0;
}
.modal-credit-base {
  top: 50%;
  left: 50%;
  position: relative;
  background-color: #fefefe;
  padding: 0;
  border: 1px solid #888;
  width: 50%;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8) translate(-50%, -50%);
  &:focus {
    outline: none;
  }
}

.modal-credit-base_after-open {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.modal-credit-base_before-close {
  transform: scale(0.2) translate(-130%, -135%);
  opacity: 0;
}

.overlay-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(66, 66, 66, 0.5);
  opacity: 0;
  transition: all 0.3s ease-out;
  z-index: 4;
}

.overlay-base_after-open {
  opacity: 1;
}

.overlay-base_before-close {
  opacity: 0;
}

// Credits

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
  .modal-base {
    top: unset;
    position: absolute;
    padding: 2rem 1rem;
    width: calc(100% - 2rem);
    bottom: 0;
    left: 0;
    opacity: 1;
    transform: translateY(120px);
  }

  .modal-base_after-open {
    transform: translateY(0px);
    opacity: 1;
  }

  .modal-base_before-close {
    transform: translateY(120px);
    opacity: 1;
  }
}
