@use "src/app/styles/colors";

.world-cup-game {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  border: 2px solid rgb(146, 143, 143);
  max-width: 400px;
  width: 100%;
  min-height: 640px;
  max-height: 711px;
  height: 100%;
  position: relative;
  background: linear-gradient(180deg,
      rgba(255, 212, 40, 0.15) 0%,
      rgba(57, 209, 180, 0.15) 100%);

  .wc-header {
    padding: 16px;

    >p {
      font-family: 'Bungee';
      line-height: 120%;
      text-align: center;
      color: colors.$text-dark;
      margin: 0;
    }

    .small {
      font-size: 1.5rem;
    }

    .large {
      font-size: 3rem;
    }
  }

  .wc-content {
    flex-grow: 1;
    overflow-y: scroll;
    margin: 0 16px;
    margin-bottom: 16px;
    padding: 16px;
    border: 1px solid rgb(64, 64, 64);
    border-radius: 20px;
    box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
      0.3em 0.3em 1em rgba(0, 0, 0, 0.3);

    &::-webkit-scrollbar {
      display: none;
    }
  }

}

@media (max-width: 576px) {
  .world-cup-game {
    border: none;
    border-radius: 0;
    max-width: unset;
    max-height: 100vh;
  }

  .leaderboard {
    border: none;
    border-radius: 0;
    max-width: unset;
    max-height: 100vh;
    overflow-y: hidden;
  }
}

.wc-options-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 20px;
  flex-grow: 1;

  .wc-option {
    width: calc(50% - 12px);
    text-align: start;
    word-break: break-word;
  }
}

.instructions {
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;

    .grid-item {
      text-align: center;
      height: 100%;

      .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }


}

.wc-game-stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 32px;

  .timer>* {
    position: relative;
    top: 0;
    left: 0;
  }
}

.wc-completed-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .game-over {
    font-family: 'Bungee';
    text-align: center;
    color: colors.$text-dark;
  }

  .next-quiz-time {
    border-radius: 8px;

    @media only screen and (max-width: 600px) {
      position: unset;
    }
  }
}

.scores-container {
  display: flex;
  column-gap: 8px;

  .score-element {

    &.correct::after {
      content: '⚽';
    }

    &.wrong::after {
      content: '❌';
    }

    &.inactive::after {
      content: '⚽';
      filter: opacity(0.3);
    }
  }
}

.wc-start-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .banner {
    width: 100%;
    height: 65%;
    border-radius: 16px;
  }

  .leaderboard-list {
    width: 100%;
    // height: 40%;
    overflow: hidden;
    overflow-y: auto;
    // margin-top: 3rem;

    table {
      width: 100%;
      border-spacing: 0px;
      margin: 0;
      padding: 1rem;
      max-width: 100%;
      font-family: "Roboto", sans-serif;

      tr {
        margin: 0;
        color: #212121;

        &.highlight {
          color: #00ad8b;
        }

        &:first-child {
          td {}
        }

        &:nth-child(2) {
          td {}
        }

        &:nth-child(3) {
          td {}
        }

        td {
          font-size: 1rem;
          padding: 0.5rem;
          font-weight: 500;
          margin: 0;
          border-color: #f0eded;
          border-bottom: 1px solid;

          &:first-child {
            padding-left: 0;
            text-align: left;
          }

          &:last-child {
            text-align: right;
            padding-right: 0px;
          }

          span {
            font-size: 0.725rem;
          }

          img {
            margin-left: 1rem;
          }
        }

        td.align-center {
          text-align: center;
        }

        td.align-left {
          text-align: left;
        }

        td.align-right {
          text-align: right;
        }
      }
    }
  }
}

.wc-leaderboard {
  text-align: center;
  width: calc(100% + 32px);
  height: calc(100% + 32px);
  margin: -16px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg,
      rgba(255, 212, 40, 0.15) 0%,
      rgba(57, 209, 180, 0.15) 100%);
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .particle-circle {
    position: absolute;
    width: 451px;
    height: 451px;
    background: #00ad8b;
    opacity: 0.1;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);

    &.inner {
      top: -254px;
    }

    &.outer {
      top: -244px;
    }

    z-index: -1;
  }

  .v-none {
    visibility: hidden;
  }

  .score {
    font-family: "Bungee";
    font-size: 2.125rem;
    line-height: 85%;
    color: #383838;
  }

  .score-container {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .score-circle {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px solid #000000;
      border-radius: 50%;
      background-color: rgba(255, 212, 40, 0.8);
      color: #212121;
      font-weight: 700;
      font-size: 1rem;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
      animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .divider {
      height: 100%;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.5);
      margin: 0 1.125rem;
    }

    .rank-circle {
      width: 60px;
      height: 60px;
      text-align: center;
      border: 1px solid #000000;
      color: #212121;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      -webkit-animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
      animation: scale-up-center 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);

      p {
        margin: 0;
        font-weight: 700;
        font-size: 1.25rem;

        sup {
          font-weight: 700;
          font-size: 0.375rem;
          line-height: 120%;
          text-transform: uppercase;
          vertical-align: 0.875rem;
        }
      }

      span {
        font-weight: 700;
        font-size: 0.625rem;
        line-height: 120%;
        text-transform: uppercase;
      }
    }
  }

  .leaderboard-list {
    width: 100%;
    // height: 25%;
    // overflow: hidden;
    overflow-y: scroll;
    margin-top: 3rem;

    table {
      width: 100%;
      border-spacing: 0px;
      margin: 0;
      padding: 1rem;
      max-width: 100%;
      font-family: "Roboto", sans-serif;

      tr {
        margin: 0;
        color: #212121;

        &.highlight {
          color: #00ad8b;
        }

        &:first-child {
          td {}
        }

        &:nth-child(2) {
          td {}
        }

        &:nth-child(3) {
          td {}
        }

        td {
          font-size: 1rem;
          padding: 0.5rem;
          font-weight: 500;
          margin: 0;
          border-color: #f0eded;
          border-bottom: 1px solid;

          &:first-child {
            padding-left: 0;
            text-align: left;
          }

          &:last-child {
            text-align: right;
            padding-right: 0px;
          }

          span {
            font-size: 0.725rem;
          }

          img {
            margin-left: 1rem;
          }
        }

        td.align-center {
          text-align: center;
        }

        td.align-left {
          text-align: left;
        }

        td.align-right {
          text-align: right;
        }
      }
    }
  }

  .btn-spoken {
    margin: 0;

    &.start {
      margin: 0;
    }
  }

  .btn-container {
    margin-top: 0;
    display: flex;
    row-gap: 16px;
    margin: 16px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    a {
      display: block;
    }

    .btn.btn-save-score {
      background-color: transparent;
      margin: 0;
      color: #00ad8b;
      text-decoration: underline;
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }

    .eg-btn {

      &.blue {
        background-color: #00ad8b;
      }

      &.small {
        padding: 3px 6px;
        font-size: 1.25rem;
      }
    }
  }
}

.wc-instructions {
  display: flex;
  flex-direction: column;
  position: relative;

  .close-btn {
    position: absolute;
    right: 0;
  }
}