@use 'src/app/styles/colors';

.answers-container {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 16px;
    flex-grow: 1;

    .option {
        width: calc(50% - 30px);
        word-break: break-word;
        text-align: start;
        border: 2px solid;
        border-radius: 2px;
        padding: 12px 8px;
        color: colors.$text-dark;
        font-family: 'Noto Sans Malayalam', sans-serif;
        font-weight: 600;

        &.wrong {
            border-color: colors.$red-dark;

            &.selected {
                border-width: 1px;
                background-color: colors.$red-light;
            }
        }

        &.correct {
            border-color: colors.$green2-dark;

            &.selected {
                border-width: 1px;
                background-color: colors.$green2-light;
            }
        }
    }

}

.swiper {
    max-width: 100%;

    .swiper-wrapper {
        height: unset;
    }

    .swiper-pagination {
        position: static;
    }

    .swiper-pagination-bullet-active {
        background: colors.$green-dark;
    }
}

@media only screen and (max-width: 600px) {

    .swiper-slide {
        overflow-y: scroll;
    }
}